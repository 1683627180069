"use client";

import PropTypes from "prop-types";
import { useEffect, useReducer, useCallback, useMemo, useState } from "react";
// utils
import customAxios from "src/utils/customAxios";
import { useSnackbar } from "src/components/snackbar";
//
import { AuthContext } from "./auth-context";
import { isValidToken, setSession } from "./utils";

const initialState = {
  user: null,
  loading: true,
  userID: null,
  facilityID: null,
  onboardingData: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIAL":
      return {
        ...state,
        loading: false,
        user: action.payload.user,
      };
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        // userID: action.payload.data.userID,
        // facilityID: action.payload.data.facilityID,
      };
    case "REGISTER":
      return {
        ...state,
        // user: action.payload.user,
      };
    case "LOGOUT":
      return {
        ...state,
        loading: false,
        user: null,
        userID: null,
        facilityID: null,
      };
    case "ONBOARDING":
      return {
        ...state,
        onboardingData: action.payload.user,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const STORAGE_KEY = "accessToken";
const STORAGE_EMAIL = "email-temporary";

export function AuthProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const onboardingInfo = JSON.parse(
          localStorage.getItem("onboardingInfo")
        );
        const response = await customAxios.get(
          `/facility/fetch/${onboardingInfo.facilityID}`
        );

        const userInformation = response.data.data;
        const data = { ...userInformation, token: accessToken };
        localStorage.setItem("userInfo", JSON.stringify(data));

        dispatch({
          type: "INITIAL",
          payload: {
            user: data,
          },
        });
      } else {
        setSession(null);
        localStorage.removeItem("userInfo");
        dispatch({ type: "LOGOUT" });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: "LOGOUT" });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(async (email, password) => {
    try {
      let data = { email, password };
      const response = await customAxios.post("/auth/facility/login", data);
      const { token, firstName, lastName, phoneNumber, userID, facilityID } =
        response.data.data;

      data = {
        ...data,
        firstName,
        lastName,
        phoneNumber,
        userID,
        facilityID,
        token,
      };

      setSession(token);
      localStorage.setItem("onboardingInfo", JSON.stringify(data));
      localStorage.setItem("userInfo", JSON.stringify(data));

      dispatch({
        type: "LOGIN",
        payload: [],
      });

      dispatch({
        type: "ONBOARDING",
        payload: { user: response.data.data },
      });

      return response;
    } catch (error) {
      enqueueSnackbar("Login error:", error, {
        variant: "error",
      });
      throw error;
    }
  }, []);

  const register = useCallback(
    async (
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      title,
      middleName,
      suffix
    ) => {
      try {
        const data = {
          email,
          password,
          firstName,
          lastName,
          phoneNumber,
          title,
          middleName,
          suffix,
          role: "Facility",
          facilityUserRole: "Admin",
        };
        const response = await customAxios.post(
          "/auth/facility/register",
          data
        );

        const { accessToken, user } = response.data;
        sessionStorage.setItem(STORAGE_KEY, accessToken);
        sessionStorage.setItem(STORAGE_EMAIL, email);

        dispatch({
          type: "REGISTER",
          payload: { user },
        });

        return response;
      } catch (error) {
        enqueueSnackbar(
          error.response.data.message || "An error has occurred",
          {
            variant: "error",
          }
        );
        throw error;
      }
    },
    []
  );

  const logout = useCallback(() => {
    setSession(null);
    localStorage.removeItem("userInfo");
    dispatch({ type: "LOGOUT" });
    // window.location.reload(); // Refresh page after logout
  }, []);

  // UPDATE
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const updateUser = useCallback(async () => {
    try {
      const response = await customAxios.get(
        `/facility/fetch/${userInfo.facilityID}`
      );

      const updatedUser = response.data.data;
      localStorage.setItem("userInfo", JSON.stringify(updatedUser));

      dispatch({
        type: "UPDATE_USER",
        payload: { user: updatedUser },
      });
      // }
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  }, [userInfo]);

  // const hasUpdatedUser = useRef(false);
  const [hasUpdatedUser, setHasUpdatedUser] = useState(false);

  useEffect(() => {
    if (state.user === null || state.user.length < 1) {
      updateUser();
      // hasUpdatedUser.current = true;
    }
  }, [state.user]);

  const status = state.loading
    ? "loading"
    : state.user
    ? "authenticated"
    : "unauthenticated";

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      userInfo: state.userInfo,
      method: "jwt",
      loading: status === "loading",
      authenticated: status === "authenticated",
      unauthenticated: status === "unauthenticated",
      onboardingData: state.onboardingData,
      login,
      register,
      logout,
      updateUser,
    }),
    [
      login,
      logout,
      register,
      state.user,
      state.userInfo,
      status,
      state.onboardingData,
      updateUser,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
